module.exports = {
    1: "mainnet",
    3: "ropsten",
    4: "rinkeby",
    314: "filecoin mainnet",
    314159: "calibration",
    11155111: "sepolia",
    42: "kovan",
    10200: "gnosis chiado testnet",
    100: "gnosis mainnet",
    137: "polygon mainnet",
    80001: "polygon mumbai testnet",
    10: "opmain",
    420: "optimism goerli testnet",
    42161: "arbitrum mainnet",
    421613: "arbitrum goerli testnet"
}